<template>
  <v-container class="pt-6">
    <BaseForm
      :title="'Publicaciones'"
      :loading="loading"
      :breadcrumbs="false"
      :elevation="0"
      @submit="handleSubmit"
    >
      <template v-slot:content>
        <v-row dense>
          <v-col cols="12" md="6">
            <date-picker-input
              label="Fecha de vencimiento"
              v-model="publicationDate.value"
              dense
              :min="minDate"
              clearable
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="6">
            <ValidationProvider
              name="Idioma"
              :rules="{
                required: !dateUpdated
              }"
              v-slot="{ errors }"
            >
              <v-select
                v-model="language"
                :items="languages"
                label="Seleccione el idioma"
                item-value="code"
                item-text="name"
                :error-messages="errors[0]"
                @change="handleLanguage"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row justify="space-between" class="mb-8">
          <v-col v-if="language" cols="12">
            <v-tabs active-class="tab-active">
              <v-tab>
                Editor HTML
              </v-tab>
              <v-tab>
                Vista Previa
              </v-tab>

              <v-tab-item class="my-6">
                <CsmlMonacoEditor
                  theme=""
                  language="html"
                  v-model="publication.value"
                  class="editor"
                ></CsmlMonacoEditor>
              </v-tab-item>
              <v-tab-item class="my-6">
                <div v-html="publication.value" />
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </template>
    </BaseForm>
  </v-container>
</template>

<script>
import BaseForm from '@/components/commons/BaseForm'
import DatePickerInput from '@/components/commons/DatePickerInput'
import SettingService from '@/services/setting'

export default {
  components: { BaseForm, DatePickerInput },
  props: {
    publicationDate: {
      type: Object,
      required: true
    },
    languages: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      language: null,
      publication: {
        key: '',
        value: '',
        type: 'string'
      },
      publicationDateClone: {}
    }
  },
  created() {
    if (this.publicationDate) this.publicationDateClone = {...this.publicationDate}
  },
  computed: {
    dateUpdated() {
      return (this.publicationDateClone.value !== null && this.publicationDateClone.value !== '') 
      && (this.publicationDate.value !== this.publicationDateClone.value)
    },
    minDate() {
      return this.$date().format('YYYY-MM-DD')
    }
  },
  methods: {
    async handleSubmit() {
      this.loading = true
      try {
        const data = [this.publicationDate]
        if (this.publication.key !== '') data.push(this.publication)
        await SettingService.post_collection(data)
        this.$dialog.notify.success('Publicación guardada con éxito.')
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },

    async handleLanguage() {
      this.loading = true
      try {
        const { data } = await SettingService.fetch({
          'fields[settings]': 'id,key,value,type',
          'filter[key]': `publications_content_${this.language}`,
        })

        if (data.data.length) {
          this.publication = data.data[0]
        } else {
          this.publication = {
            key: `publications_content_${this.language}`,
            value: '',
            type: 'string'
          }
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style scoped>
.editor {
  height: 500px;
}
</style>
