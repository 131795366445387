<template>
  <v-text-field
    v-bind="$attrs"
    v-mask="phoneMask"
    v-on="{ ...$listeners, input: event => handleInput }"
    placeholder="(000) 000-00-00"
    :value="value"
    @input="handleInput"
  >
  </v-text-field>
</template>

<script>
import { unmask } from '@/utils/mask'

export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      number: '',
      phoneMask: '(###) ###-##-##'
      // phoneMask: '(###) ###-###-##-##'
    }
  },
  methods: {
    handleInput(value) {
      this.number = unmask(value, this.phoneMask)
      this.$emit('input', unmask(value, this.phoneMask))
    }
  }
}
</script>
