<template>
  <v-container class="pt-6">
    <BaseForm
      title="Estado de Cuenta"
      :loading="loading"
      :breadcrumbs="false"
      :elevation="0"
      @submit="handleSubmit"
    >
      <template v-slot:content>
        <v-row justify="space-between" class="mb-8">
          <v-col cols="12" md="6">
            <ValidationProvider
              name="Meses de antiguedad"
              rules="required"
              v-slot="{ errors }"
            >
              <v-select
                :items="months"
                label="Cantidad de meses de antiguedad para generar estados de cuenta"
                v-model="accountStatement.months_old_allowed.value"
                :error-messages="errors[0]"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider
              name="Días permitidos"
              rules="required"
              v-slot="{ errors }"
            >
              <v-select
                :items="daysOptions"
                label="Cantidad de días permitidos para sustituir estados de cuenta"
                v-model="accountStatement.substitution_day_allowed.value"
                :error-messages="errors[0]"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <v-switch
              v-model="accountStatement.external_download.value"
              label="External Download"
            ></v-switch>
          </v-col>
        </v-row>
      </template>
    </BaseForm>
  </v-container>
</template>

<script>
import BaseForm from '@/components/commons/BaseForm'
import SettingService from '@/services/setting'
import months from '@/api/months'
import days from '@/api/days'
export default {
  components: { BaseForm },
  props: {
    accountStatement: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      months: months
    }
  },
  computed: {
    daysOptions() {
      const options = days.map(item => {
        return { text: item, value: item }
      })
      return [...options, { text: 'Sin limite', value: 0 }]
    }
  },
  methods: {
    async handleSubmit() {
      this.loading = true

      const data = []
      Object.keys(this.accountStatement).forEach(key => {
        data.push(this.accountStatement[key])
      })

      try {
        await SettingService.post_collection(data)
        this.$dialog.notify.success('Parámetros guardados con éxito.')
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style></style>
