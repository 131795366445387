<template>
  <v-container>
    <VWidget
      :title="$route.meta.title"
      :loading="loading"
      @submit="handleSubmit"
    >
      <template v-slot:content>
        <v-tabs active-class="tab-active">
          <v-tab>
            General
          </v-tab>
          <v-tab>
            Conectividad
          </v-tab>
          <v-tab>
            Datos de Contacto
          </v-tab>
          <v-tab>
            Publicaciones
          </v-tab>
          <v-tab>
            Estado de Cuenta
          </v-tab>

          <v-tab-item>
            <general class="my-6" :settings="settings" />
          </v-tab-item>
          <v-tab-item>
            <conectivity class="my-6" :conectivities="conectivities" />
          </v-tab-item>
          <v-tab-item>
            <contact class="my-6" :contacts="contacts" />
          </v-tab-item>
          <v-tab-item>
            <publications :publicationDate="publicationDate" :languages="languages" class="my-6"/>
          </v-tab-item>
          <v-tab-item>
            <account-statement :accountStatement="accountStatement" class="my-6"/>
          </v-tab-item>
        </v-tabs>
      </template>
    </VWidget>
  </v-container>
</template>
<script>
import VWidget from '@/components/commons/VWidget'
import General from './partials/General'
import Conectivity from './partials/Conectivity'
import Contact from './partials/Contact'
import Publications from './partials/Publications'
import AccountStatement from './partials/AccountStatement'
import SettingService from '@/services/setting'
import LanguageService from '@/services/language'

export default {
  components: { VWidget, General, Conectivity, Contact, Publications, AccountStatement },
  data() {
    return {
      loading: false,
      settings: {},
      languages: [],
      conectivities: {},
      contacts: {},
      publicationDate: {},
      accountStatement: {}
    }
  },
  async created() {
    this.loading = true
    try {
      await this.populateSettings()
      await this.populateLanguages()
      this.populateConectivities()
      this.populatePublication()
      this.populateAccountStatement()
    } catch (error) {
      console.log(error)
    } finally {
      this.loading = false
    }
  },
  methods: {
    async populateSettings() {
      const { data } = await SettingService.fetch({
        'fields[settings]': 'id,key,value,type'
      })
      this.settings = data.data
    },
    async populateLanguages() {
      const { data } = await LanguageService.fetch()
      this.languages = data.data
    },
    getSetting(key) {
      return this.settings.find(item => item.key == key)
    },

    populateConectivities() {
      this.conectivities.av_api_url = this.getSetting('av_api_url')
      this.conectivities.av_api_key = this.getSetting('av_api_key')
    },

    populatePublication() {
      this.publicationDate = this.getSetting('publications_date')
    },

    populateAccountStatement() {
      this.accountStatement.months_old_allowed = this.getSetting('months_old_allowed')
      this.accountStatement.substitution_day_allowed = this.getSetting('substitution_day_allowed')
      this.accountStatement.external_download = this.getSetting('external_download')
    },

    handleSubmit() {
      /*  const { data } = DisplayPeriodService.fetch()
            this.displayPeriods = data.data
      try {
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }*/
    }
  }
}
</script>
<style scoped>
.v-tab {
  text-transform: none !important;
  font-weight: bold;
}
</style>
