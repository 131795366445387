var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-6"},[_c('BaseForm',{attrs:{"title":'Contacto',"loading":_vm.loading,"breadcrumbs":false,"elevation":0},on:{"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Teléfono Principal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('phone-input-panam',{attrs:{"label":"Teléfono Principal","placeholder":"Teléfono Principal","error-messages":errors[0]},model:{value:(_vm.setting.contact_phone_main.value),callback:function ($$v) {_vm.$set(_vm.setting.contact_phone_main, "value", $$v)},expression:"setting.contact_phone_main.value"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Teléfono Secundario","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('phone-input-panam',{attrs:{"label":"Teléfono Secundario","placeholder":"Teléfono Secundario","error-messages":errors[0]},model:{value:(_vm.setting.contact_phone_secondary.value),callback:function ($$v) {_vm.$set(_vm.setting.contact_phone_secondary, "value", $$v)},expression:"setting.contact_phone_secondary.value"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('ValidationProvider',{attrs:{"name":"Dirección","rules":"required|min:2|max:500|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textArea",attrs:{"label":"Dirección","error-messages":errors[0]},model:{value:(_vm.setting.contact_address.value),callback:function ($$v) {_vm.$set(_vm.setting.contact_address, "value", $$v)},expression:"setting.contact_address.value"}})]}}])})],1)],1),_c('v-row',{staticClass:"mt-8"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"Correo","rules":"required|email|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"append-icon":"mdi-email","label":"Correo Electrónico de Contacto","autocomplete":"nope","error-messages":errors[0]},model:{value:(_vm.setting.contact_email.value),callback:function ($$v) {_vm.$set(_vm.setting.contact_email, "value", $$v)},expression:"setting.contact_email.value"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"Facebook","rules":"min:2|max:500|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.labelFacebook,"placeholder":_vm.placeholderFacebook,"append-icon":"mdi-facebook","hint":"www.facebook.com/","persistent-hint":"","clearable":"","error-messages":errors[0]},model:{value:(_vm.setting.contact_facebook.value),callback:function ($$v) {_vm.$set(_vm.setting.contact_facebook, "value", $$v)},expression:"setting.contact_facebook.value"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"Twitter","rules":"min:2|max:500|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.labelTwitter,"placeholder":_vm.placeholderTwitter,"append-icon":"mdi-twitter","hint":"www.twitter.com/","persistent-hint":"","clearable":"","error-messages":errors[0]},model:{value:(_vm.setting.contact_twitter.value),callback:function ($$v) {_vm.$set(_vm.setting.contact_twitter, "value", $$v)},expression:"setting.contact_twitter.value"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"Instagram","rules":"min:2|max:500|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.labelInstagram,"append-icon":"mdi-instagram","hint":"www.instagram.com/","placeholder":_vm.placeholderInstagram,"clearable":"","persistent-hint":"","error-messages":errors[0]},model:{value:(_vm.setting.contact_instagram.value),callback:function ($$v) {_vm.$set(_vm.setting.contact_instagram, "value", $$v)},expression:"setting.contact_instagram.value"}})]}}])})],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }