<template>
  <v-container class="pt-6">
    <BaseForm
      title="Conectividad"
      :loading="loading"
      :breadcrumbs="false"
      :elevation="0"
      @submit="handleSubmit"
    >
      <template v-slot:content>
        <v-row justify="space-between" class="mb-8">
          <v-col cols="12">
            <ValidationProvider
              name="AV API URL"
              rules="required"
              v-slot="{ errors }"
            >
              <v-text-field
                label="API URL*"
                placeholder="API URL"
                v-model="conectivities.av_api_url.value"
                :error-messages="errors[0]"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider
              name="AV API KEY"
              rules="required"
              v-slot="{ errors }"
            >
              <v-text-field
                label="API KEY*"
                placeholder="API Key"
                v-model="conectivities.av_api_key.value"
                :error-messages="errors[0]"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
      </template>
    </BaseForm>
  </v-container>
</template>

<script>
import BaseForm from '@/components/commons/BaseForm'
import SettingService from '@/services/setting'
export default {
  components: { BaseForm },
  props: {
    conectivities: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    async handleSubmit() {
      this.loading = true

      const data = []
      Object.keys(this.conectivities).forEach(key => {
        data.push(this.conectivities[key])
      })

      try {
        await SettingService.post_collection(data)
        this.$dialog.notify.success('Conectividad guardada con éxito.')
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style></style>
