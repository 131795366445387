<template>
  <v-container class="pt-6">
    <BaseForm
      :title="'Contacto'"
      :loading="loading"
      :breadcrumbs="false"
      :elevation="0"
      @submit="handleSubmit"
    >
      <template v-slot:content>
        <v-row>
          <v-col cols="12" md="6">
            <ValidationProvider
              name="Teléfono Principal"
              rules="required"
              v-slot="{ errors }"
            >
              <phone-input-panam
                label="Teléfono Principal"
                placeholder="Teléfono Principal"
                v-model="setting.contact_phone_main.value"
                :error-messages="errors[0]"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider
              name="Teléfono Secundario"
              rules="required"
              v-slot="{ errors }"
            >
              <phone-input-panam
                label="Teléfono Secundario"
                placeholder="Teléfono Secundario"
                v-model="setting.contact_phone_secondary.value"
                :error-messages="errors[0]"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12">
            <ValidationProvider
              name="Dirección"
              rules="required|min:2|max:500|"
              v-slot="{ errors }"
            >
              <v-text-field
                label="Dirección"
                class="textArea"
                v-model="setting.contact_address.value"
                :error-messages="errors[0]"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row class="mt-8">
          <v-col cols="12" md="3">
            <ValidationProvider
              name="Correo"
              rules="required|email|max:255"
              v-slot="{ errors }"
            >
              <v-text-field
                append-icon="mdi-email"
                label="Correo Electrónico de Contacto"
                v-model="setting.contact_email.value"
                autocomplete="nope"
                :error-messages="errors[0]"
              >
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="3">
            <ValidationProvider
              name="Facebook"
              rules="min:2|max:500|"
              v-slot="{ errors }"
            >
              <v-text-field
                :label="labelFacebook"
                :placeholder="placeholderFacebook"
                append-icon="mdi-facebook"
                v-model="setting.contact_facebook.value"
                hint="www.facebook.com/"
                persistent-hint
                clearable
                :error-messages="errors[0]"
              >
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="3">
            <ValidationProvider
              name="Twitter"
              rules="min:2|max:500|"
              v-slot="{ errors }"
            >
              <v-text-field
                :label="labelTwitter"
                :placeholder="placeholderTwitter"
                append-icon="mdi-twitter"
                v-model="setting.contact_twitter.value"
                hint="www.twitter.com/"
                persistent-hint
                clearable
                :error-messages="errors[0]"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="3">
            <ValidationProvider
              name="Instagram"
              rules="min:2|max:500|"
              v-slot="{ errors }"
            >
              <v-text-field
                :label="labelInstagram"
                append-icon="mdi-instagram"
                v-model="setting.contact_instagram.value"
                hint="www.instagram.com/"
                :placeholder="placeholderInstagram"
                clearable
                persistent-hint
                :error-messages="errors[0]"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
      </template>
    </BaseForm>
  </v-container>
</template>

<script>
import SettingService from '@/services/setting'
import BaseForm from '@/components/commons/BaseForm'
import PhoneInputPanam from '@/components/commons/PhoneInputPanam'

export default {
  components: { BaseForm, PhoneInputPanam },
  data() {
    return {
      select_questions: [1, 2, 3],
      select_twofa: [
        'Acceso al portal',
        'Solicitudes de Transferencia',
        'Notificaciones de Depósito'
      ],
      select_recaptcha: ['Habilitado', 'Deshabilitado'],
      settings: [],
      setting: {
        contact_email: null,
        contact_phone_main: null,
        contact_phone_secondary: null,
        contact_address: null,
        contact_facebook: null,
        contact_twitter: null,
        contact_instagram: null
      },
      setting_array: [],
      loading: false,
      selected2FA: []
    }
  },
  async created() {
    this.loading = true
    try {
      await this.populateSettings()
      await this.populateGlobal()
    } catch (e) {
      if (e.response.status !== 401)
        this.$dialog.message.error('No se pudo cargar el registro.')
    } finally {
      this.loading = false
    }
  },
  methods: {
    async handleSubmit() {
      this.loading = true
      try {
        await SettingService.post_collection(this.setting_array)
        this.$dialog.notify.success('Parámetros guardados con éxito.')
      } catch (e) {
        if (e.response.status !== 401) this.$dialog.notify.error(e.message)
      } finally {
        this.loading = false
        if (this.setting.recaptcha.value == true) {
          this.setting.recaptcha.value = 'Habilitado'
        } else {
          this.setting.recaptcha.value = 'Deshabilitado'
        }
      }
    },
    async populateSettings() {
      const { data } = await SettingService.fetch()
      this.settings = data.data
    },
    getSetting(key) {
      return this.settings.find(item => item.key == key)
    },
    populateGlobal() {
      this.setting.contact_email = this.getSetting('contact_email')
      this.setting_array[0] = this.setting.contact_email

      this.setting.contact_phone_main = this.getSetting('contact_phone_main')
      this.setting_array[1] = this.setting.contact_phone_main

      this.setting.contact_phone_secondary = this.getSetting(
        'contact_phone_secondary'
      )
      this.setting_array[2] = this.setting.contact_phone_secondary

      this.setting.contact_address = this.getSetting('contact_address')
      this.setting_array[3] = this.setting.contact_address

      this.setting.contact_facebook = this.getSetting('contact_facebook')
      this.setting_array[4] = this.setting.contact_facebook

      this.setting.contact_twitter = this.getSetting('contact_twitter')
      this.setting_array[5] = this.setting.contact_twitter

      this.setting.contact_instagram = this.getSetting('contact_instagram')
      this.setting_array[6] = this.setting.contact_instagram
    }
  },
  computed: {
    label2FA() {
      if (this.selected2FA.length === 0) {
        return `2FA - No configurado`
      }
      return `2FA`
    },
    labelInstagram() {
      if (!this.setting.contact_instagram.value) {
        return 'Instagram deshabilitado'
      } else {
        return 'Instagram'
      }
    },
    placeholderInstagram() {
      if (!this.setting.contact_instagram.value) {
        return 'Instagram deshabilitado'
      } else {
        return this.setting.contact_instagram.value
      }
    },
    labelTwitter() {
      if (!this.setting.contact_twitter.value) {
        return 'Twitter deshabilitado'
      } else {
        return 'Twitter'
      }
    },
    placeholderTwitter() {
      if (!this.setting.contact_twitter.value) {
        return 'Twitter deshabilitado'
      } else {
        return this.setting.contact_twitter.value
      }
    },
    labelFacebook() {
      if (!this.setting.contact_facebook.value) {
        return 'Facebook deshabilitado'
      } else {
        return 'Facebook'
      }
    },
    placeholderFacebook() {
      if (!this.setting.contact_facebook.value) {
        return 'Facebook deshabilitado'
      } else {
        return this.setting.contact_facebook.value
      }
    }
  }
}
</script>

<style lang="sass">
.textArea
  textarea
    max-height: 30px
</style>
