<template>
  <v-container class="pt-6">
    <BaseForm
      :title="'Generales'"
      :loading="loading"
      :breadcrumbs="false"
      :elevation="0"
      @submit="handleSubmit"
    >
      <template v-slot:content>
        <v-row justify="space-between" class="mb-8">
          <v-col cols="12" md="6" class="">
            <v-select
              :items="select_questions"
              label="Cantidad de Preguntas de Seguridad"
              v-model="setting.quantity_questions.value"
            ></v-select>
          </v-col>
          <!--v-col cols="12" md="6" class="">
            <v-select
              :items="select_recaptcha"
              label="Recaptcha"
              v-model="setting.recaptcha.value"
            ></v-select>
          </v-col-->
          <v-col cols="12" md="6" class="">
            <v-select
              :items="select_twofa"
              v-model="selected2FA"
              :label="label2FA"
              multiple
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row justify="space-between" class="mb-8">
          <v-col cols="12" md="6" class="">
            <v-select
              :items="select_questions"
              label="Cantidad de  Intentos de Login Errado"
              v-model="setting.login.value"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-switch
              v-model="setting.white_branding.value"
              label="White Branding"
            ></v-switch>
          </v-col>
        </v-row>
      </template>
    </BaseForm>
  </v-container>
</template>

<script>
import SettingService from '@/services/setting'
import BaseForm from '@/components/commons/BaseForm'

export default {
  components: { BaseForm },
  data() {
    return {
      select_questions: [1, 2, 3],
      select_twofa: [
        'Acceso al portal',
        'Solicitudes de Transferencia',
        'Notificaciones de Depósito'
      ],
      select_recaptcha: ['Habilitado', 'Deshabilitado'],
      settings: [],
      setting: {
        quantity_questions: null,
        twofa: null,
        twofa_access: null,
        twofa_transfer: null,
        twofa_deposit: null,
        login: null,
        recaptcha: null,
        active: true
      },
      setting_array: [],
      loading: false,
      selected2FA: []
    }
  },
  async created() {
    this.loading = true
    try {
      await this.populateSettings()
      await this.populateGlobal()
    } catch (error) {
      console.log(error)
      this.$dialog.message.error('No se pudo cargar el registro.')
    } finally {
      this.loading = false
    }
  },
  methods: {
    async handleSubmit() {
      this.loading = true
      try {
        if (this.setting.recaptcha.value == 'Habilitado') {
          this.setting.recaptcha.value = true
        } else {
          this.setting.recaptcha.value = false
        }
        const access = this.selected2FA.find(
          element => element == 'Acceso al portal'
        )
        const transfer = this.selected2FA.find(
          element => element == 'Solicitudes de Transferencia'
        )
        const deposit = this.selected2FA.find(
          element => element == 'Notificaciones de Depósito'
        )

        if (access) {
          this.setting.twofa_access.value = true
        } else {
          this.setting.twofa_access.value = false
        }
        if (transfer) {
          this.setting.twofa_transfer.value = true
        } else {
          this.setting.twofa_transfer.value = false
        }
        if (deposit) {
          this.setting.twofa_deposit.value = true
        } else {
          this.setting.twofa_deposit.value = false
        }

        if (!access && !transfer && !deposit) {
          this.setting.twofa.value = false
        } else {
          this.setting.twofa.value = true
        }
        await SettingService.post_collection(this.setting_array)
        this.$dialog.notify.success('Parámetros guardados con éxito.')
      } catch (e) {
        if (e.response.status !== 401) this.$dialog.notify.error(e.message)
      } finally {
        this.loading = false
        if (this.setting.recaptcha.value == true) {
          this.setting.recaptcha.value = 'Habilitado'
        } else {
          this.setting.recaptcha.value = 'Deshabilitado'
        }
      }
    },
    async populateSettings() {
      const { data } = await SettingService.fetch()
      this.settings = data.data
    },
    getSetting(key) {
      return this.settings.find(item => item.key == key)
    },
    populateGlobal() {
      this.setting.quantity_questions = this.getSetting('quantity_questions')
      this.setting_array[0] = this.setting.quantity_questions

      this.setting.twofa = this.getSetting('2fa')
      this.setting_array[1] = this.setting.twofa

      this.setting.twofa_access = this.getSetting('2fa_portal_access')
      this.setting_array[2] = this.setting.twofa_access
      if (this.setting.twofa_access.value) {
        this.selected2FA.push('Acceso al portal')
      }

      this.setting.twofa_transfer = this.getSetting('2fa_transfer_requests')
      this.setting_array[3] = this.setting.twofa_transfer
      if (this.setting.twofa_transfer.value) {
        this.selected2FA.push('Solicitudes de Transferencia')
      }

      this.setting.twofa_deposit = this.getSetting('2fa_deposit_notifications')
      this.setting_array[4] = this.setting.twofa_deposit
      if (this.setting.twofa_deposit.value) {
        this.selected2FA.push('Notificaciones de Depósito')
      }

      this.setting.login = this.getSetting('max_login_error')
      this.setting_array[5] = this.setting.login

      this.setting.recaptcha = this.getSetting('recaptcha')
      this.setting_array[6] = this.setting.recaptcha

      this.setting.white_branding = this.getSetting('white_branding')
      this.setting_array[7] = this.setting.white_branding

      this.setting.months_old_allowed = this.getSetting('months_old_allowed')
      this.setting_array[8] = this.setting.months_old_allowed

      if (this.setting.recaptcha.value == true) {
        this.setting.recaptcha.value = 'Habilitado'
      } else {
        this.setting.recaptcha.value = 'Deshabilitado'
      }
    }
  },
  computed: {
    label2FA() {
      if (this.selected2FA.length === 0) {
        return `2FA - No configurado`
      }
      return `2FA`
    }
  }
}
</script>
