<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :append-icon="appendIcon"
        v-bind="$attrs"
        v-on="on"
        :value="date | date"
        autocomplete="off"
        @click:append="menu = true"
        @input="$emit('input', $event)"
        readonly
        :clearable="clearable"
      />
    </template>
    <v-date-picker 
      :value="date" 
      no-title 
      locale="es-es" 
      @input="handleInput" 
      :min="min"
      :max="max"
    />
  </v-menu>
</template>

<script>
export default {
  name: 'DatePickerInput',
  props: {
    value: {
      type: [Date, String, Number],
      default: ''
    },
    appendIcon: {
      type: String,
      default: 'mdi-calendar'
    },
    min: {
      type: String
    },
    max: {
      type: String
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      menu: false
    }
  },
  computed: {
    date() {
      return this.value
    }
  },
  methods: {
    handleInput(value) {
      this.menu = false
      this.$emit('input', value)
    }
  }
}
</script>

<style></style>
